import React  from 'react'
import './Portfolio.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import Slidebar from "../../img/sidebar.png"
import Ecommerce from "../../img/ecommerce.png"
import HOC from "../../img/hoc.png"
import MusicApp from "../../img/musicapp.png"
import 'swiper/css'

import { themeContext } from '../../Context'
import { useContext } from 'react'



const Portfolio=()=>{
    const theme=useContext(themeContext);
    const darkMode=theme.state.darkMode;
    return(
        <div className="portfolio" id='Portifolio'>

            {/*  */}
            <span style={{color:darkMode?'white':""}}>Recent Projects</span>
            <span>Portfolio</span>
            <Swiper
             spaceBetween={30}
             slideperView={4}
             grabCursor={true}
             
             
             className='portfolio-slider'>
                
                <SwiperSlide>
                    <img src={Slidebar} alt=""/>
                    
                </SwiperSlide>
                <SwiperSlide>
                <img src={Ecommerce} alt=""/>
                    
                </SwiperSlide>
                <SwiperSlide>
                <img src={HOC} alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                <img src={MusicApp} alt=""/>

                </SwiperSlide>

            </Swiper>
        </div>
    )
}
export default Portfolio;